import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Mobility Drills`}</p>
    <p>{`\\
Full Clean 5×2\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`8:00 ascending amrap:`}</p>
    <p>{`Power Clean (135/95) (rx+ 165/110)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`*`}{`add 1 rep each round until time expires.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      